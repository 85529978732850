import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { openPopupWidget } from "react-calendly"

import HeaderFlat from "../components/HeaderFlat"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import FooterGlobal from "../components/FooterGlobal"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MainNav from "../components/MainNav"

import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"
import { FaStar } from "react-icons/fa"
import { FaStarHalfAlt } from "react-icons/fa"
import { FaAngleLeft } from "react-icons/fa"
import { FaAngleRight } from "react-icons/fa"

export const getData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "google-reviews") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const GoogleReviews = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  const [show, setShow] = useState(0)

  const handleAccordion = id => {
    if (show === id) {
      setShow(0)
    } else {
      setShow(id)
    }
  }

  const handleCalendly = () => {
    const url = "https://calendly.com/rayjenscode/15"
    openPopupWidget({ url })
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav active="services" />
        <HeaderFlat
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}

        // background={heroBG}
        // contact={contactNumber}
        />

        <section className="google-reviews">
          <div className="google-reviews__header container">
            <div className="google-reviews__header__left">
              <p className="google-reviews__header__left--note">
                GET MORE GOOGLE REVIEWS
              </p>
              <h1 className="google-reviews__header__left--heading">
                Powerful review software that increases Google reviews
                guaranteed.
              </h1>
              <h2 className="google-reviews__header__left--subheading">
                We have generated almost thousand of reviews for our clients
                business websites on autopilot. These reviews will get you found
                online.
                <span className="google-reviews__header__left--span">
                  FREE!
                </span>
              </h2>

              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Speak with me about <span className="text-bold">reviews</span>
              </button>
            </div>
          </div>

          <div className="google-reviews__pitch">
            <h2 className="google-reviews__pitch--text">
              Over 90% of consumers check online reviews{" "}
              <span className="google-reviews__pitch--text--span">
                before making a buying decision.
              </span>{" "}
              Don't disappoint them.
            </h2>
          </div>
          <div className="google-reviews__cover container">
            <h2 className="google-reviews__cover--heading">
              Shortcut to Local Customers
            </h2>

            <p className="google-reviews__cover--subheading">
              Online reviews have become one of the biggest deciding factors for
              consumers when choosing a local business to work with. In fact,
              over 75% of consumers trust online reviews as much as a
              recommendation from a close friend. I put your reviews on
              autopilot and make the process easier for you and your customers.
            </p>

            <div className="google-reviews__cover--imgWrapper">
              <img
                src={require("../images/googlereviews.png")}
                alt="Great Web Google Review result"
                className="google-reviews__cover--img"
              />
            </div>

            <h2 className="google-reviews__cover--heading">
              Stand out from competing companies
            </h2>

            <p className="google-reviews__cover--subheading">
              Your quantity of reviews and overall ranking on Google are
              dealbreakers for most consumers. This means that if you have more
              5-star reviews than your competitors, you will control the
              majority of the search traffic - even if your not ranked in the #1
              spot!
            </p>

            <div className="google-reviews__cover--imgWrapper">
              <img
                src={require("../images/googlereviews-2.png")}
                alt="Great Web Google Review result"
                className="google-reviews__cover--img"
              />
            </div>

            <h2 className="google-reviews__cover--heading">
              Get Reviews On The Sites That Matter Most...
            </h2>

            <p className="google-reviews__cover--subheading">
              While Google reviews are typically the most impactful for your
              business, there are other review sites you won’t want to ignore. I
              can connect your review link to the review sites that matter most
              to ensure a balanced and dominant review portfolio.
            </p>

            <div className="google-reviews__cover--imgWrapper">
              <img
                src={require("../images/googlereviews-3.png")}
                alt="Great Web Google Review result"
                className="google-reviews__cover--img"
              />
            </div>

            <BookStrategyBtnPrimary />
          </div>

          <div className="google-reviews__result container">
            <h2 className="google-reviews__result--heading">Client Results</h2>

            <div className="google-reviews__result--listWrapper">
              <ul className="google-reviews__result--list">
                <li className="google-reviews__result--item">
                  <div className="google-reviews__result--item--before">
                    <p className="google-reviews__result--item--heading--before">
                      Before Great Web
                    </p>
                    <p className="google-reviews__result--item--before--value">
                      4
                    </p>
                    <div className="google-reviews__result--item--rating">
                      <span className="google-reviews__result--item--rating--span">
                        3.1
                      </span>
                      <ul className="google-reviews__result--item--star--list">
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                      </ul>
                    </div>
                    <div className="google-reviews__result--item--google--imgWrapper">
                      <img
                        className="google-reviews__result--item--google--img"
                        src={require("../images/google-logo.png")}
                        alt="Great Web Review Result"
                      />
                    </div>
                  </div>
                  <div className="google-reviews__result--vertical-line vertical-line"></div>
                  <div className="google-reviews__result--item--after">
                    <p className="google-reviews__result--item--heading--after">
                      With Great Web
                    </p>
                    <p className="google-reviews__result--item--after--value">
                      150
                    </p>
                    <div className="google-reviews__result--item--rating">
                      <span className="google-reviews__result--item--rating--span">
                        4.9
                      </span>
                      <ul className="google-reviews__result--item--star--list">
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStar className="google-reviews__result--item--star--item--icon" />
                        </li>
                        <li className="google-reviews__result--item--star--item">
                          <FaStarHalfAlt className="google-reviews__result--item--star--item--icon" />
                        </li>
                      </ul>
                    </div>
                    <div className="google-reviews__result--item--google--imgWrapper">
                      <img
                        className="google-reviews__result--item--google--img"
                        src={require("../images/google-logo.png")}
                        alt="Great Web Review Result"
                      />
                    </div>
                  </div>
                </li>
              </ul>

              <h3 className="google-reviews__result--company">
                2EAS Accounting Solutions
              </h3>

              <div className="google-reviews__result--btnWrapper ">
                <div className="google-reviews__result--prevBtn">
                  <FaAngleLeft className="google-reviews__result--btn--icon" />
                </div>
                <div className="google-reviews__result--nextBtn">
                  <FaAngleRight className="google-reviews__result--btn--icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="google-reviews__faq container">
            <h3 className="google-reviews__faq--heading">
              Frequently Asked Questions
            </h3>
            <ul className="google-reviews__faq--list">
              <li className="google-reviews__faq--item">
                <h4
                  className="google-reviews__faq--item--question"
                  onPointerDown={() => handleAccordion(1)}
                >
                  <div>
                    {show === 1 ? (
                      <FaMinus className="google-reviews__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="google-reviews__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="google-reviews__faq--item--question--span">
                    How does it work?
                  </span>
                </h4>
                {/* {show === 1 && ( */}
                <div
                  className={`${
                    show === 1
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="google-reviews__faq--item--description">
                    The #1 reason customers don't leave reviews in{" "}
                    <span className="google-reviews__faq--item--description--span">
                      convenience.
                    </span>{" "}
                    It is a pain to go online, find a company, and figure out
                    how and where to leave a review. Besides that, customers
                    don't know they are important and because of this, only
                    leave reviews when they are upset and need to "vent".
                  </p>
                  <p className="google-reviews__faq--item--description">
                    Our review process eliminated this issue by making the
                    process of leaving a review as{" "}
                    <span className="google-reviews__faq--item--description--span">
                      quick
                    </span>{" "}
                    and{" "}
                    <span className="google-reviews__faq--item--description--span">
                      {" "}
                      convenient{" "}
                    </span>{" "}
                    as possible.
                  </p>
                  <p className="google-reviews__faq--item--description">
                    I provide your business with a unique review request link
                    that can't be sent in a text message to each of your
                    customers. With{" "}
                    <span className="google-reviews__faq--item--description--span">
                      one click
                    </span>
                    , they can be taken directly to the correct page to leave a
                    review (Google, Facebook, etc.) For many businesses, over{" "}
                    <span className="google-reviews__faq--item--description--span">
                      50% of customers leave a review when they receive this
                      review{" "}
                    </span>{" "}
                    request message and the best part is they are{" "}
                    <span className="google-reviews__faq--item--description--span">
                      all positive reviews
                    </span>
                    .
                  </p>
                  <p className="google-reviews__faq--item--description">
                    We filter out the negative ones to protect your online
                    reputation. Don't worry, you'll receive the negative review
                    in an email to deal with privately.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="google-reviews__faq--item">
                <h4
                  className="google-reviews__faq--item--question"
                  onPointerDown={() => handleAccordion(2)}
                >
                  <div>
                    {show === 2 ? (
                      <FaMinus className="google-reviews__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="google-reviews__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="google-reviews__faq--item--question--span">
                    What do I need to get started?
                  </span>
                </h4>
                {/* {show === 2 && ( */}
                <div
                  className={`${
                    show === 2
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="google-reviews__faq--item--description">
                    Getting started is easy. All you need is a Google My
                    Business listing (free at{" "}
                    <a
                      className="link"
                      href="https://www.google.com/business"
                      target="__blank"
                    >
                      https://www.google.com/business
                    </a>{" "}
                    ).
                  </p>
                  <p className="google-reviews__faq--item--description">
                    If you don't have one setup already, don't stress. I can
                    help you get things set up properly.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="google-reviews__faq--item">
                <h4
                  className="google-reviews__faq--item--question"
                  onPointerDown={() => handleAccordion(3)}
                >
                  <div>
                    {show === 3 ? (
                      <FaMinus className="google-reviews__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="google-reviews__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="google-reviews__faq--item--question--span">
                    How many reviews can this get me?
                  </span>
                </h4>
                {/* {show === 3 && ( */}
                <div
                  className={`${
                    show === 3
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="google-reviews__faq--item--description">
                    This completely depends on the amount of customers you have
                    come through your business and how many review request
                    messages you send out. Some businesses have higher
                    quantities of new customers each day which makes it even
                    easier to get reviews.
                  </p>
                  <p className="google-reviews__faq--item--description">
                    Either way, most of the time it is fair to say an average of
                    30-40 new 5-star reviews every month.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="google-reviews__faq--item">
                <h4
                  className="google-reviews__faq--item--question"
                  onPointerDown={() => handleAccordion(4)}
                >
                  <div>
                    {show === 4 ? (
                      <FaMinus className="google-reviews__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="google-reviews__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="google-reviews__faq--item--question--span">
                    Do online reviews even matter?
                  </span>
                </h4>
                {/* {show === 4 && ( */}
                <div
                  className={`${
                    show === 4
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="google-reviews__faq--item--description">
                    Yes yes yes. Online reviews are the quickest way to make a
                    splash online and start landing new customers. A higher
                    overall ranking and quantity of reviews can immediately set
                    you apart from competitions and make you the first choice
                    for prospective customers.
                  </p>
                </div>
                {/* )} */}
              </li>
            </ul>
            <div className="google-reviews__ready">
              <h4 className="google-reviews__ready--heading">
                Ready to Get Started?
              </h4>
              <p className="google-reviews__ready--subheading">
                If you want to learn more about how I can help grow your
                business, click below to schedule a free growth call. If you are
                ready to start right away,{" "}
                <Link className="google-reviews__ready--link" to="/contact">
                  click here to contact me.
                </Link>
              </p>
              {/* 
              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Schedule Call Now - It's Free
              </button> */}
              <BookStrategyBtnPrimary />
            </div>
          </div>
        </section>

        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default GoogleReviews
